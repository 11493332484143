var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.restoringSnapshot === true, "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialog = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var tooltip = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    icon: "",
                                    text: "",
                                    color: "primary"
                                  }
                                },
                                Object.assign({}, tooltip, dialog)
                              ),
                              [
                                _vm.isRestoreToCurrentInstance
                                  ? _c("v-icon", [_vm._v("restore")])
                                  : _c("v-icon", [_vm._v("group")])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm.isRestoreToCurrentInstance
                    ? _c("span", [
                        _vm._v("Restore to the current state of this instance")
                      ])
                    : _c("span", [
                        _vm._v(
                          "Restore to the current state of a target instance"
                        )
                      ])
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.restoreDialog,
        callback: function($$v) {
          _vm.restoreDialog = $$v
        },
        expression: "restoreDialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", [_vm._v("restore")]),
              _c("span", { staticClass: "ml-1 primary--text text-uppercase" }, [
                _vm._v("snapshot restore")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.restoringSnapshot === true, icon: "" },
                  on: {
                    click: function($event) {
                      _vm.restoreDialog = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("clear")])],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _vm.isColdSnapshot
                ? _c(
                    "v-banner",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: { slot: "icon", color: "white", size: "30" },
                          slot: "icon"
                        },
                        [
                          _c("v-icon", { attrs: { color: "info" } }, [
                            _vm._v("info")
                          ])
                        ],
                        1
                      ),
                      _vm._v(
                        "This snapshot is in cold storage, it might take longer than usual to restore its contents. "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.snapshotData
                ? _c("div", [
                    _c("div", { staticClass: "mt-5" }, [
                      _c("div", [
                        _c("span", [_vm._v("Snapshot name")]),
                        _vm._v(": "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.snapshotData.long_id))
                        ])
                      ]),
                      _c("div", [
                        _c("span", [_vm._v("Snapshot timestamp")]),
                        _vm._v(": "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateTimeToHuman")(
                                _vm.snapshotData.snapshot_timestamp
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _vm.isRestoreToCurrentInstance
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "mt-5",
                                attrs: {
                                  outlined: "",
                                  color: "error",
                                  prominent: "",
                                  type: "warning"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "Here is what happens when you restore a snapshot to the current state"
                                    )
                                  ]
                                ),
                                _c("ul", { staticClass: "subtitle-2 mt-3" }, [
                                  _c("li", [
                                    _vm._v(" The "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-decoration-underline font-weight-bold"
                                      },
                                      [_vm._v("current state")]
                                    ),
                                    _vm._v(
                                      " of this instance will be be overwritten with the contents of the snapshot "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-decoration-underline font-weight-bold"
                                      },
                                      [_vm._v(_vm._s(_vm.snapshotData.long_id))]
                                    ),
                                    _vm._v(". ")
                                  ]),
                                  _c("li", { staticClass: "subtitle-2" }, [
                                    _vm._v(" An auto snapshot of the "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-decoration-underline font-weight-bold"
                                      },
                                      [_vm._v("current state")]
                                    ),
                                    _vm._v(
                                      " of this instance will be created (to be able to revert this operation). "
                                    )
                                  ])
                                ]),
                                _c("v-divider", {
                                  staticClass: "my-4 primary",
                                  staticStyle: { opacity: "0.22" }
                                }),
                                _c("v-checkbox", {
                                  staticClass: "primary--text",
                                  attrs: {
                                    color: "error",
                                    label: "I understand"
                                  },
                                  model: {
                                    value: _vm.agreeBox,
                                    callback: function($$v) {
                                      _vm.agreeBox = $$v
                                    },
                                    expression: "agreeBox"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("h3", { staticClass: "mt-5" }, [
                              _vm._v("Target Instance")
                            ]),
                            _c("v-select", {
                              attrs: {
                                items: _vm.instanceNames,
                                label: "Select target instance to overwrite",
                                disabled:
                                  _vm.restoring || !_vm.sourceSnapshotName
                              },
                              model: {
                                value: _vm.selectedInstance,
                                callback: function($$v) {
                                  _vm.selectedInstance = $$v
                                },
                                expression: "selectedInstance"
                              }
                            }),
                            _vm.selectedInstance
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-5",
                                    attrs: {
                                      outlined: "",
                                      color: "error",
                                      prominent: "",
                                      type: "warning"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          "Here is what happens when overwrite the current state of a target instance"
                                        )
                                      ]
                                    ),
                                    _c("ul", { staticClass: "mt-3" }, [
                                      _c("li", [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle-2" },
                                          [
                                            _vm._v(
                                              " The contents (files, tables, and application) of the current state of the instance "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-decoration-underline font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedInstance)
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " will be cleared and replaced with the contents of "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-decoration-underline font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sourceSnapshotName
                                                  ) + "."
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("li", { staticClass: "subtitle-2" }, [
                                        _vm._v(
                                          " A restorable auto snapshot of the current state of "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-decoration-underline font-weight-bold"
                                          },
                                          [_vm._v(_vm._s(_vm.selectedInstance))]
                                        ),
                                        _vm._v(
                                          " will be created (to be able to revert this operation). "
                                        )
                                      ])
                                    ]),
                                    _c("v-divider", {
                                      staticClass: "my-4 primary",
                                      staticStyle: { opacity: "0.22" }
                                    }),
                                    _c("v-checkbox", {
                                      staticClass: "primary--text",
                                      attrs: {
                                        color: "error",
                                        label: "I understand"
                                      },
                                      model: {
                                        value: _vm.agreeBox,
                                        callback: function($$v) {
                                          _vm.agreeBox = $$v
                                        },
                                        expression: "agreeBox"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column justify-center align-center",
                staticStyle: { width: "100%" }
              },
              [
                _vm.isRestoreToCurrentInstance
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-checkbox",
                                    _vm._g(
                                      {
                                        staticClass: " ma-0 pa-0",
                                        attrs: {
                                          label:
                                            "Create a backup snapshot of the current state before restoring"
                                        },
                                        model: {
                                          value: _vm.createBackupSnapshot,
                                          callback: function($$v) {
                                            _vm.createBackupSnapshot = $$v
                                          },
                                          expression: "createBackupSnapshot"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          954997982
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "This option is recommended in order to create backup and allow for restoring old objects in the future."
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center justify-end mt-5",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "secondary",
                          text: "",
                          disabled: _vm.restoringSnapshot || _vm.restoring
                        },
                        on: { click: _vm.reviewSnapshot }
                      },
                      [_vm._v("review snapshot")]
                    ),
                    _vm.isRestoreToCurrentInstance
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              text: "",
                              color: "error",
                              loading: _vm.restoringSnapshot,
                              disabled: _vm.restoringSnapshot || !_vm.agreeBox
                            },
                            on: { click: _vm.restoreSnapshotToCurrentState }
                          },
                          [_vm._v("Restore")]
                        )
                      : _c(
                          "v-btn",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              color: "error",
                              text: "",
                              loading: _vm.restoring,
                              disabled:
                                _vm.restoring ||
                                !_vm.selectedInstance.length ||
                                !_vm.agreeBox
                            },
                            on: { click: _vm.overwriteCurrentState }
                          },
                          [_vm._v(" overwrite ")]
                        )
                  ],
                  1
                ),
                _c(
                  "v-alert",
                  {
                    staticClass: "mt-5",
                    attrs: {
                      value: _vm.error,
                      color: "error",
                      prominent: "",
                      type: "error",
                      text: ""
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Snapshot Restoration Error")
                      ]),
                      _vm._v(
                        " An error has occurred and the snapshot could not be restored. Please try again later and if the problem persists contact support@alphacruncher.com "
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }