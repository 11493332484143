<template>
    <v-dialog :persistent="restoringSnapshot === true" v-model="restoreDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" icon text color="primary">
                        <v-icon v-if="isRestoreToCurrentInstance">restore</v-icon>
                        <v-icon v-else>group</v-icon></v-btn
                    >
                </template>
                <span v-if="isRestoreToCurrentInstance">Restore to the current state of this instance</span>
                <span v-else>Restore to the current state of a target instance</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <v-icon>restore</v-icon>
                <span class="ml-1 primary--text text-uppercase">snapshot restore</span>
                <v-spacer></v-spacer>
                <v-btn :disabled="restoringSnapshot === true" @click="restoreDialog = false" icon><v-icon>clear</v-icon></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-banner v-if="isColdSnapshot" class="mt-3">
                    <v-avatar slot="icon" color="white" size="30"> <v-icon color="info">info</v-icon> </v-avatar>This snapshot is in cold storage, it might take
                    longer than usual to restore its contents.
                </v-banner>
                <div v-if="snapshotData">
                    <div class="mt-5">
                        <div>
                            <span>Snapshot name</span>: <span class="font-weight-bold">{{ snapshotData.long_id }}</span>
                        </div>
                        <div>
                            <span>Snapshot timestamp</span>: <span class="font-weight-bold">{{ snapshotData.snapshot_timestamp | dateTimeToHuman }}</span>
                        </div>
                    </div>
                    <div v-if="isRestoreToCurrentInstance">
                        <v-alert outlined color="error" prominent type="warning" class="mt-5">
                            <span class="font-weight-bold">Here is what happens when you restore a snapshot to the current state</span>
                            <ul class="subtitle-2 mt-3">
                                <li>
                                    The <span class="text-decoration-underline font-weight-bold">current state</span> of this instance will be be overwritten
                                    with the contents of the snapshot <span class="text-decoration-underline font-weight-bold">{{ snapshotData.long_id }}</span
                                    >.
                                </li>
                                <li class="subtitle-2">
                                    An auto snapshot of the <span class="text-decoration-underline font-weight-bold">current state</span> of this instance will
                                    be created (to be able to revert this operation).
                                </li>
                            </ul>
                            <v-divider class="my-4 primary" style="opacity: 0.22"></v-divider>
                            <v-checkbox class="primary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                        </v-alert>
                    </div>
                    <div v-else>
                        <h3 class="mt-5">Target Instance</h3>
                        <v-select
                            :items="instanceNames"
                            v-model="selectedInstance"
                            label="Select target instance to overwrite"
                            :disabled="restoring || !sourceSnapshotName"
                        ></v-select>
                        <v-alert v-if="selectedInstance" outlined color="error" prominent type="warning" class="mt-5">
                            <span class="font-weight-bold">Here is what happens when overwrite the current state of a target instance</span>
                            <ul class="mt-3">
                                <li>
                                    <span class="subtitle-2">
                                        The contents (files, tables, and application) of the current state of the instance
                                        <span class="text-decoration-underline font-weight-bold">{{ selectedInstance }}</span> will be cleared and replaced with
                                        the contents of
                                        <span class="text-decoration-underline font-weight-bold">{{ sourceSnapshotName }}.</span>
                                    </span>
                                </li>
                                <li class="subtitle-2">
                                    A restorable auto snapshot of the current state of
                                    <span class="text-decoration-underline font-weight-bold">{{ selectedInstance }}</span> will be created (to be able to revert
                                    this operation).
                                </li>
                            </ul>
                            <v-divider class="my-4 primary" style="opacity: 0.22"></v-divider>
                            <v-checkbox class="primary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                        </v-alert>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <div style="width:100%" class="d-flex flex-column justify-center align-center">
                    <v-tooltip v-if="isRestoreToCurrentInstance" top>
                        <template v-slot:activator="{ on }">
                            <v-checkbox
                                class=" ma-0 pa-0"
                                v-on="on"
                                v-model="createBackupSnapshot"
                                label="Create a backup snapshot of the current state before restoring"
                            ></v-checkbox>
                        </template>
                        <span>This option is recommended in order to create backup and allow for restoring old objects in the future.</span>
                    </v-tooltip>
                    <div class="d-flex align-center justify-end mt-5" style="width:100%">
                        <v-btn color="secondary" @click="reviewSnapshot" text :disabled="restoringSnapshot || restoring">review snapshot</v-btn>
                        <v-btn
                            v-if="isRestoreToCurrentInstance"
                            text
                            class="mr-1"
                            color="error"
                            :loading="restoringSnapshot"
                            :disabled="restoringSnapshot || !agreeBox"
                            @click="restoreSnapshotToCurrentState"
                            >Restore</v-btn
                        >
                        <v-btn
                            v-else
                            color="error"
                            class="mr-1"
                            text
                            :loading="restoring"
                            @click="overwriteCurrentState"
                            :disabled="restoring || !selectedInstance.length || !agreeBox"
                        >
                            overwrite
                        </v-btn>
                    </div>
                    <v-alert :value="error" color="error" prominent type="error" class="mt-5" text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Snapshot Restoration Error</span>
                            An error has occurred and the snapshot could not be restored. Please try again later and if the problem persists contact
                            support@alphacruncher.com
                        </div>
                    </v-alert>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { isEditorRole, isMasterOrDistributedInstance } from '@/utils'

export default {
    name: 'SnapshotRestoreDialog',
    props: {
        snapshotData: Object,
        isColdSnapshot: Boolean,
        isRestoreToCurrentInstance: Boolean
    },
    data() {
        return {
            error: false,
            createBackupSnapshot: true,
            agreeBox: false,
            restoring: false,
            selectedInstance: '',
            restoreDialog: false
        }
    },
    methods: {
        restoreSnapshotToCurrentState: function() {
            this.$store
                .dispatch('snapshotStore/restoreSnapshotToCurrentState', { snid: this.$props.snapshotData.snid, createBackup: this.createBackupSnapshot })
                .finally(() => {
                    this.$data.restoreDialog = false
                })
        },
        overwriteCurrentState: function() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Overwriting target, please wait....',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.$data.error = false
            this.$data.restoring = true
            const iid = this.spaceInstances.find(instance => instance.long_id.toUpperCase() === this.$data.selectedInstance.toUpperCase()).iid
            this.$axios
                .put(`snapshots/${this.$props.snapshotData.snid}/load_to/${iid}`, {}, { timeout: 300000 })
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Current state successfully overwritten by ' + this.sourceSnapshotName,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$data.restoreDialog = false
                })
                .catch(() => {
                    this.$data.error = true
                })
                .finally(() => {
                    this.$data.restoring = false
                })
        },
        reviewSnapshot: function() {
            this.$router.push({
                name: 'snapshot-overview',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$props.snapshotData.snid
                }
            })
        }
    },
    computed: {
        ...mapState('snapshotStore', ['restoringSnapshot']),
        ...mapState('spaceStore', ['spaceInstances']),
        sourceSnapshotName: function() {
            return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$props.snapshotData.snid)
        },
        instanceNames: function() {
            const editorInstances = this.spaceInstances.filter(instance => isEditorRole(instance.role) === true)
            const instances = editorInstances.map(instance => instance.long_id)
            return instances.filter(instance => isMasterOrDistributedInstance(instance) === false)
        }
    },
    watch: {
        restoreDialog: function(nextVal) {
            if (nextVal === false && this.$data.error === true) {
                this.$data.error = false
            }
        }
    }
}
</script>
